export default [
    {
        headerName: null,
        checkboxSelection: true,
        pinned: 'left',
        sortable: false,
        filter: false,
        width: 70,
    },
    {
        headerName: 'ID',
        field: 'id',
    },
    {
        headerName: 'First Name',
        field: 'firstName',
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
    },
    {
        headerName: 'Role',
        field: 'type',
    },
    {
        headerName: 'email',
        field: 'email',
    },
    {
        headerName: 'Username',
        field: 'username',
    },
    {
        headerName: 'Locked Until',
        field: 'LockedUntil',
    },
]
