<template>
    <div>
        <v-btn
            color="default"
            :disabled="loading || !hasAccessRole"
            :loading="loading"
            @click="unlock"
            >Unlock</v-btn
        >

        <base-dialog
            v-model="showDialog"
            alert
            title="User Account Unlocked"
            :confirm-action="removeRow"
            confirm-text="Ok"
        >
            <template v-slot:content>
                <v-card-text>
                    <v-container fluid>
                        <p>{{ name }} user account {{ username }} is unlocked</p>
                    </v-container>
                </v-card-text>
            </template>
        </base-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseDialog from '@/components/modal/BaseDialog.vue'
import { ROLE_SETUP_DISTRICT_EDIT } from '@/helpers/security/roles'

export default {
    components: { BaseDialog },
    data() {
        return {
            loading: false,
            showDialog: false,
        }
    },
    computed: {
        ...mapGetters({ clientId: 'getIlluminateClientId', hasRole: 'auth/hasRole' }),
        name() {
            return `${this?.params?.data?.firstName} ${this?.params?.data?.lastName}'s`
        },
        username() {
            return this.params?.data?.username
        },
        hasAccessRole() {
            return this.hasRole(ROLE_SETUP_DISTRICT_EDIT)
        },
    },
    methods: {
        ...mapActions({ patch: 'account/patch' }),
        async unlock() {
            this.loading = true
            const result = await this.patch({
                accountId: this.params.data.id,
                clientId: this.clientId,
                data: { lockedUntil: null },
            })

            if (!result.error) {
                this.showDialog = true
            }
            this.loading = false
        },
        removeRow: async function () {
            await this.params.api.refreshServerSideStore({ purge: false })
        },
    },
}
</script>

<style></style>
