<template>
    <div>
        <h3>{{ title }}</h3>
        <ag-grid-server-side
            v-if="clientId"
            id="lockedAccounts"
            :column-defs="columnDefs"
            :server-side-datasource="serverSideDatasource"
        />
        <div v-else>No client Id set</div>
    </div>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/account'
import pagination from '@/helpers/ag-grid/pagination/rest'
import { mapGetters, mapActions } from 'vuex'
import LockedUsersUnlockBtn from '@/views/DistrictManagement/Authentication/LockedUsersUnlockBtn'

export default {
    name: 'UsersTable',
    components: {
        AgGridServerSide,
        /* eslint-disable vue/no-unused-components */
        LockedUsersUnlockBtn,
    },
    props: {
        query: {
            type: Object,
            default: () => {},
        },
        title: {
            type: String,
            default: 'Accounts',
        },
    },
    data: () => ({
        columnDefs: [
            ...ColumnDef,
            {
                pinned: 'right',
                cellRendererFramework: 'LockedUsersUnlockBtn',
            },
        ],
    }),
    computed: {
        ...mapGetters({
            clientId: 'getIlluminateClientId',
            getAccounts: 'account/getItems',
            getAccountMeta: 'account/getMetadata',
        }),
    },
    methods: {
        ...mapActions({
            fetchAccounts: 'account/cget',
        }),
        serverSideDatasource: function () {
            const fetch = async (queryPayload) => {
                await this.fetchAccounts({
                    clientId: this.clientId,
                    params: {
                        ...queryPayload,
                        ...this.query,
                    },
                })

                return {
                    items: this.getAccounts,
                    metadata: this.getAccountMeta,
                }
            }

            return {
                getRows: async function (params) {
                    const { items, metadata } = await fetch(pagination(params))
                    params.successCallback(items, metadata.count)
                },
            }
        },
    },
}
</script>

<style></style>
